@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap");

body {
  margin: 0;
  font-family: "Arial", sans-serif;
}

/* Menu Styles */
.menu {
  font-family: "Ubuntu", sans-serif;
  background-color: #f3f3f3;
  padding: 10px 0;
  text-align: center;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu ul li {
  display: inline;
  margin-right: 20px;
}

.menu ul li a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}

.menu ul li a:hover {
  color: #0275d8;
}

/* Login Form Styles */
.login-form {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.login-form h1 {
  text-align: center;
}

.login-form label {
  display: block;
  margin-bottom: 5px;
}

.login-form input[type="text"],
.login-form input[type="email"],
.login-form input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Include padding and border in the element's width and height */
}

.login-form button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #0275d8;
  color: white;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box; /* Include padding and border in the element's width and height */
}

.login-form button:hover {
  background-color: #025aa5;
}

/* Spiiner */
.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1000; /* Ensures it's above other elements */
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0; /* Added to ensure it covers the entire width */
  bottom: 0; /* Added to ensure it covers the entire height */
  display: flex;
  justify-content: center; /* Centers the modal horizontally */
  align-items: center; /* Centers the modal vertically */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal {
  width: 600px; /* Set the width of the modal */
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  display: flex;
  flex-direction: column;
}

.modal-footer {
  display: flex;
  padding: 20px 20px;
}
.modal-body {
  padding: 20px 20px;
  border-bottom: 1px solid #e0e0e0; /* Border line at the bottom */
}

.modal-body-form-field {
  display: flex;
}
.modal-body-form-field:not(:last-child) {
  margin-bottom: 15px;
  display: flex;
}

.modal-body-form-label {
  width: 30%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #344054;
  word-wrap: "break-word";
  font-family: "Arial";
}

.modal-body-form-input {
  width: 70%; /* Adjust the width as needed */
  padding: 10px; /* Adjust the padding as needed */
  border: 1px solid #d0d5dd;
  border-radius: 7.5px; /* Matching the radius used in the SVG */
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #7d8da8;
}

.modal-body-form-select {
  width: 74%; /* Adjust the width as needed */
  padding: 10px; /* Adjust the padding as needed */
  border: 1px solid #d0d5dd;
  border-radius: 7.5px; /* Matching the radius used in the SVG */
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #7d8da8;
  appearance: none; /* Remove default arrow in some browsers */
  background-color: white; /* Set background color */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%237D8DA8"><path d="M7 10l5 5 5-5z" /></svg>'); /* Add custom arrow */
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer; /* Show pointer cursor on hover */
}

/* Style for the dropdown arrow icon */
.modal-body-form-select::after {
  content: ''; /* Empty content for the pseudo-element */
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0;
  border-color: #7d8da8 transparent transparent;
  transform: translateY(-50%);
}

.modal-header {
  background-color: #fff;
  color: #101828;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items from the top */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 20px;
}

.modal-title {
  font-size: 18px; /* Large font size for the title */
  font-weight: bold; /* Make the title bold */
  margin-bottom: 8px; /* Add some bottom margin to create space between title and subtitle */
}
.modal-subtitle {
  width: 100%;
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.modal-close-btn {
  overflow: hidden;
  position: relative;
  border: none;
  padding: 0;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: transparent;
  color: #667085;
  font: inherit;
  text-indent: 100%;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
}

.modal-close-btn:focus {
  outline: solid 0 transparent;
  box-shadow: 0 0 0 2px #777b83;
}

.modal-close-btn:before,
.modal-close-btn:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.125em;
  height: 70%;
  border-radius: 0.125em;
  background: currentColor;
  content: "";
  display: block;
  transform: translate(-50%, -50%) rotate(45deg);
}

.modal-close-btn:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modal-footer button.close {
  flex: 1; /* Distribute available space equally among buttons */
  background-color: white;
  color: #344054;
  border: 1px solid #d0d5dd;
  border-radius: 7.5px;
  padding: 8px 16px;
  margin: 0 4px; /* Add some spacing between buttons */
  cursor: pointer;
  font-size: 14px;
}

.modal-footer button.save {
  flex: 1; /* Distribute available space equally among buttons */
  background-color: rgb(0, 123, 255);
  color: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 7.5px;
  padding: 8px 16px;
  margin: 0 4px; /* Add some spacing between buttons */
  cursor: pointer;
  font-size: 14px;
}


/*Users table*/
.content-container {
  padding: 10px;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

h2 {
  margin: 0;
  font-weight: 400;
  font-size: 1.0em;
}

.order-count {
  background: #eff4ff;
  border: 1px solid #cfdffe;
  color: #5e7bef;
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 0.9em;
  margin-left: 10px;
}

.table-filters {
  display: flex; /* Aligns the buttons next to each other */
}

.filter-btn {
  padding: 5px 15px;
  border: 1px solid #e1e4e8;
  background-color: #fff; /* White background */
  color: #6b6d76; /* Text color */
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  margin-right: -1px; /* Negative margin to overlap borders */
}

.filter-btn:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.filter-btn:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.filter-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.filter-btn:hover,
.filter-btn.active {
  background-color: #fafafa; /* Lighter background for hover and active */
  color: #14151a; /* Lighter text color for hover and active */
}

.table-container {
  background: #fff;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  overflow: hidden;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #fafafb;
  border-bottom: 1px solid #e1e4e8;
  font-size:14px;
}

th {
  text-align: left;
  padding: 12px 15px;
  font-weight: 400;
  color: #626262;
}

tbody tr {
  border-bottom: 1px solid #e1e4e8;
}

td {
  padding: 12px 15px;
  color: #5a5c67;
  font-size:16px;
}

.status-indicator {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.paid {
  background-color: #28a745;
}

.failed {
  background-color: #dc3545;
}

.pending {
  background-color: #ffc107;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.pagination-info {
  /* Center the pagination text */
  flex-grow: 1;
  text-align: center;
  font-size:14px;
  color: #6b6d76;

}

.page-link {
  color: #007bff;
  padding: 5px 10px;
  margin: 0 5px;
  text-decoration: none;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
}

.page-link.filter-btn {
  padding: 5px 15px;
  border: 1px solid #e1e4e8;
  background-color: #fff;
  color: #6b6d76;
  cursor: pointer;
  outline: none;
  font-size:14px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 8px;
  -webkit-appearance: none; /* removes default browser styles for buttons */
  -moz-appearance: none;
  appearance: none;
}

.page-link.filter-btn:hover,
.page-link.filter-btn.active {
  color: #696d78;
}

.page-link.filter-btn:first-child,
.page-link.filter-btn:last-child {
  border-radius: 8px;
}

/* Popup Menu */
.popupMenu {
  position: absolute;
  z-index: 1000; /* Make sure it appears above other elements */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  background-color: white;
  border: 1px solid #e0e0e0; /* Lighter border for a softer look */
  border-radius: 6px; /* Slightly rounded corners */
  padding: 5px 0;
  min-width: 100px;
}

/* Popup Menu Button */
.popupMenu button {
  background: none;
  border: none;
  color: #333; /* Slightly darker for better readability */
  padding: 10px 20px; /* Comfortable padding */
  text-align: left;
  width: 100%;
  font-size: 15px; /* Slightly larger font */
  cursor: pointer;
  transition: background-color 0.2s; /* Smooth transition for hover effect */
}

/* Hover Effect for Buttons */
.popupMenu button:hover {
  background-color: #f9f9f9; /* Very light grey for hover */
}

/* Popup Menu Button Styles */
.popupMenu button {
  background: none;
  border: none;
  color: #333; /* Dark grey for readability */
  padding: 10px 20px; /* Ample padding for easy interaction */
  text-align: left;
  width: 100%;
  font-size: 15px; /* Slightly larger font for clarity */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  display: block; /* Ensures full width */
}

/* Hover Effect for Buttons */
.popupMenu button:hover {
  background-color: #e8e8e8; /* Light grey background on hover */
  color: #007bff; /* Change text color on hover for visual feedback */
}
/* Minimalist Style for the '...' Button */
.option {
  background: none;
  border: none;
  color: #333; /* Dark enough for visibility, yet soft */
  font-size: 14px; /* Adjust size as needed */
  cursor: pointer;
  padding: 5px 10px; /* Minimal padding */
  display: inline-block; /* To properly align and space */
  vertical-align: middle; /* Aligns with other inline or inline-block elements */
  transition: color 0.3s; /* Smooth transition for hover effect */
}

.option:hover {
  color: #007bff; /* Slight color change on hover for interactivity */
}


/* HOME PAGE */
.custom-container {
  width: 100%;
  max-width: 1200px;
  height: 1000px;
  margin: 0 auto;
}
/* Navbar Styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.navbar-logo {
  font-weight: 200;
  font-size: 24px;
}

.navbar-options {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 0px !important;
}

.navbar-options li {
  font-weight: 200;
  text-decoration: none;
  list-style: none;
  margin: 5px 10px;
}

.hero-content {
  z-index: 1; /* Place content above the video */
  color: white; /* Set text color */
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.hero-title {
  font-size: 3rem;
  margin-bottom: 10px;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.cta-button {
  color: white; /* Set button text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.headline {
  font-family: 'Euclid Circular A', sans-serif;
  font-weight: 500;
  font-size: 82px;
  line-height: 80px;
  letter-spacing: -4px;
  color: #FCFCFF;
  padding-bottom: 41px;
}

.subline {
  font-family: 'Euclid Circular A', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -1px;
  color: #D5D5D6;
}

/* Mobile Menu Styles */
.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

.menu-toggle {
  display: none;
}

/* Style the "About Us" section */
.about-us {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
}

.about-image svg {
  width: 100%; /* Adjust to your preferred width */
  height: auto;
}

.about-content {
  padding: 0 40px;
}

.mission-section h2, .about-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.mission-section p, .about-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.logo-container{
  display: flex;
  align-items: flex-start;
}
.logo-svg {
  max-height: 200px;
  width: auto;
}

.mission-section{
  margin-top: 50px;
}

.services-section{
  padding: 10px 20px;
}
/* Media Query for Mobile */
@media (max-width: 899px) {
  .navbar-options {
    display: none;
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    padding: 10px 0;
  }

  .navbar-options.open {
    display: flex;
  }

  .menu-toggle {
    display: flex;
    align-items: center;
  }
  
  .menu-icon {
    display: block;
    cursor: pointer;
    font-size: 24px;
    transition: transform 0.3s ease; /* Add a transition for the rotation */
  }
  
  .menu-icon.rotate {
    transform: rotate(360deg); /* Rotate the icon to mimic the CloseIcon */
  }
  .logo-svg {
    display: none;
  }
  .about-content {
    padding: 0 0;
  }

  .headline{
    font-size:45px;
    line-height: 55px;
  }
}
